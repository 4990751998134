<template>
  <div>
    <Header />
    <div class="logo">
      <img class="logo_pic" src="../../../assets/order/buy_logo.png" alt="" />
      <div class="buzhou">
        <svg class="logo-search-progress">
          <image
            x="0"
            y="30"
            width="130"
            height="6"
            xlink:href="../../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="129"
            y="30"
            width="130"
            height="6"
            xlink:href="../../../assets/settlement/progress-03.png"
          ></image>
          <image
            x="259"
            y="30"
            width="130"
            height="6"
            xlink:href="../../../assets/settlement/progress-03.png"
          ></image>
          <image
            x="50"
            y="8"
            width="27"
            height="38"
            xlink:href="../../../assets/settlement/progress-icon-02.png"
          ></image>
          <image
            x="180"
            y="8"
            width="27"
            height="38"
            xlink:href="../../../assets/settlement/progress-icon-03.png"
          ></image>
          <image
            x="310"
            y="8"
            width="27"
            height="38"
            xlink:href="../../../assets/settlement/progress-icon-03.png"
          ></image>
          <text x="60" y="38" style="fill:#fff;">1</text>
          <text x="190" y="38" style="fill:#fff;">2</text>
          <text x="320" y="38" style="fill:#fff;">3</text>
          <text x="25" y="58" style="fill:#ff902a;">1.填写充值金额</text>
          <text x="165" y="58" style="fill:#dedede;">2.在线支付</text>
          <text x="295" y="58" style="fill:#dedede;">3.充值完成</text>
        </svg>
      </div>
    </div>
    <div class="chong">
      <div class="tit">填写充值金额</div>
      <div class="zhanghu">
        充值账户：<span class="zhang">bw_ZyFGTFKYQDpP</span>
      </div>
      <div class="jine">
        充值金额：<span class="jin"
          >只能填写大于等于10，小于等于50000的整数金额</span
        >
        <div class="num">
          <input type="text" />
        </div>
        元
      </div>

      <div class="zhuyi">
        请注意：支持国内主流银行储蓄卡充值，在线支付成功后，充值金额会在1分钟内到账；如果需要提现，请联系<span
          class="rengong"
          >人工客服</span
        >办理
      </div>
      <div class="next">下一步</div>
      <div class="tishi">
        <div class="tishi1">
          <img src="../../../assets/order/tishi.png" alt="" />
          <span class="spen">温馨提示</span>
        </div>
        <div class="tiaoli">
          1.充值成功后，余额可能存在延迟现象，一般1到5分钟内到账，如有问题，请咨询客服；
        </div>
        <div class="tiaoli">
          2.充值金额输入值必须是不小于10且不大于50000的正整数；
        </div>
        <div class="tiaoli">
          3.您只能用储蓄卡进行充值，如遇到任何支付问题可以查看在线支付帮助；
        </div>
        <div class="tiaoli">
          4.充值完成后，您可以进入账户充值记录页面进行查看余额充值状态。
        </div>
      </div>
    </div>
    <Baoyou />
    <Foot />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Baoyou from '@/components/order/baoyou.vue';
export default {
  components: {
    Header,
    Baoyou,
    Foot,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 1175px;
  margin: 0 auto;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .logo_pic {
    width: 157px;
    height: 44px;
  }
  .buzhou {
    width: 612px;
    .logo-search-progress {
      width: 100%;
      height: 104px;
    }
  }
}
.chong {
  width: 1237px;
  margin: 0 auto;
  border: 2px solid #ebebeb;
  padding-bottom: 46px;
  .tit {
    height: 50px;
    line-height: 50px;
    background: #f5f5f5;
    font-size: 22px;
    color: #656565;
    padding-left: 232px;
  }
  .zhanghu {
    font-size: 14px;
    color: #787878;
    padding-left: 232px;
    margin-top: 46px;
    .zhang {
      color: #272727;
    }
  }
  .jine {
    margin-top: 12px;
    padding-left: 232px;
    font-size: 14px;
    color: #787878;
    display: flex;
    .jin {
      color: #e54142;
    }
    .num {
      width: 101px;
      height: 33px;
      border: 2px solid #adadad;
      border-radius: 5px;
      margin: 0 5px;
      margin-top: -5px;
      line-height: 33px;
      padding: 2px 3px;
      input {
        border: 0;
        outline: none;
        width: 95%;
      }
    }
  }
  .zhuyi {
    font-size: 12px;
    color: #999;
    padding-left: 232px;
    .rengong {
      color: #fe912a;
    }
  }
  .next {
    margin-left: 232px;
    width: 138px;
    height: 40px;
    background: #1850ff;
    color: #fff;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
    border-radius: 5px;
  }
  .tishi {
    width: 767px;
    background: #f2f7fd;
    border: 1px solid #ecf5fa;
    margin: 37px auto;
    padding-bottom: 40px;
    .tishi1 {
      margin-left: 97px;
      margin-top: 29px;
      display: flex;
      align-items: center;
      img {
        width: 16px;
        height: 16px;
      }
      .spen {
        font-size: 15px;
        color: #87888a;
        margin-left: 10px;
      }
    }
    .tiaoli {
      margin-left: 120px;
      font-size: 14px;
      color: #85898c;
      margin-top: 10px;
    }
  }
}
</style>
